import { request } from '../request';
// 地区设备分布
const areaDeviceCount = function (params) {
  return request({
    url: 'api/area/device_count',
    method: 'post',
    data: params
  })
}
// 故障报警信息
const areaAlarm = function (params) {
  return request({
    url: 'api/area/alarm',
    method: 'post',
    data: params
  })
}
// 年月日采油量及能耗
const areaTotalProd = function (params) {
  return request({
    url: 'api/area/total_production',
    method: 'post',
    data: params
  })
}
// 地区7日采油量及能耗
const areaHistoryProdPower = function (params) {
  return request({
    url: 'api/area/history_production_power',
    method: 'post',
    data: params
  })
}
// 地区实时采油量
const areaDeviceProd = function (params) {
  return request({
    url: 'api/area/device_production',
    method: 'post',
    data: params
  })
}
// 地区日设备上线率
const areaDeviceOnline = function (params) {
  return request({
    url: 'api/area/device_online',
    method: 'post',
    data: params
  })
}
// 地区设备故障率
const areaDeviceFault = function (params) {
  return request({
    url: 'api/area/device_fault',
    method: 'post',
    data: params
  })
}

// 油田设备表格
const areaOilFieldMap = function (params) {
  return request({
    url: 'api/area/oilfield_map',
    method: 'post',
    data: params
  })
}
// 生产简讯
const areaMessage = function (params) {
  return request({
    url: 'api/area/message',
    method: 'post',
    data: params
  })
}

export default {
  areaDeviceCount, areaAlarm, areaTotalProd, areaHistoryProdPower, areaDeviceProd, areaDeviceOnline, areaDeviceFault, areaOilFieldMap, areaMessage
}
