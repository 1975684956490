<template>
  <div class="container">
    <el-table
      :data="tableData"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      height="524"
      @row-click="rowClick"
    >
      <el-table-column min-width="86">
        <template slot="header" slot-scope="scope">
          <div class="lineBg"></div>
        </template>
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p>{{ scope.row.device_name }}</p>
            <div slot="reference" class="name-wrapper">
              {{ scope.row.device_name }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="Work_S" label="工作状态" min-width="107">
      </el-table-column>
      <el-table-column prop="T_Poweron_T" label="上电时长" min-width="107">
      </el-table-column>
      <el-table-column prop="DC_Va" label="电压" min-width="107">
      </el-table-column>
      <el-table-column prop="DC_Main_Ia" label="电流" min-width="107">
      </el-table-column>
      <el-table-column prop="Downhole_T" label="井下温度" min-width="107">
      </el-table-column>
      <el-table-column prop="Downhole_P" label="井下压力" min-width="107">
      </el-table-column>
      <el-table-column prop="T_Production" label="当日采油量" min-width="107">
      </el-table-column>
      <el-table-column prop="T_PowerC" label="当日耗电量" min-width="107">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'OilFiledTable',
  props: {
    tableData: Array
  },
  data: () => {
    return {}
  },
  methods: {
    rowClick(row) {
      this.$emit('toSingleMachine', row.device_name)
    },
    headerCellStyle() {
      return {
        height: '46px',
        'text-align': 'center',
        'font-size': '14px',
        'font-family': 'Source Han Sans CN',
        color: 'rgba(228, 228, 228, 1)',
        background: 'rgba(29, 84, 128, 0.5)'
      }
    },
    cellStyle() {
      return 'text-align:center'
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.container {
  //table 表格start
  ::v-deep {
    .el-table {
      margin-top: 14px;
      width: 1070px;
      &::before {
        height: 0px;
      }
      .lineBg {
        width: 100%;
        height: 100%;
        background: url('https://pump7niu.ahsanen.com/img/oilField/line.png');
        background-size: 100% 100%;
      }
      .gutter {
        background: rgba(29, 84, 128, 0.5);
      }
      .el-table__body-wrapper::-webkit-scrollbar {
        width: 17px;
      }
      .el-table__body-wrapper::-webkit-scrollbar-thumb {
        height: 50px;
        background: #0c5ea7;
      }

      table th,
      table td {
        padding: 0px !important;
        border: 1px solid RGBA(0, 6, 24, 1) !important;
      }
      .el-table__row {
        td {
          width: 100%;
          height: 12px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(228, 228, 228, 1);
        }
      }
    }
    .el-table,
    .el-table__expanded-cell {
      background-color: transparent;
    }
    .el-table tr {
      background-color: rgba(4, 40, 72, 0.6) !important;
    }
    .el-table tr td:first-child {
      background-color: rgba(29, 84, 128, 0.5) !important;
    }
    .el-table--enable-row-transition .el-table__body td,
    .el-table .cell {
      padding: 0px 0px !important;
      height: 46px;
      line-height: 38px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      background-color: transparent;
    }
    .el-table .color-row {
      background: rgba(4, 40, 72, 0.8) !important;
    }
  }
}
</style>
